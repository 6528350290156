import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { useState } from "react"
import { isEmail } from "src/utils/utils"

import { useAddCollaborator } from "src/queries/credit/useAddCollaborator"

export default ({
  applicationId,
  onClose,
}: {
  applicationId: string
  onClose: () => void
}) => {
  const [signerEmail, setSignerEmail] = useState("")
  const { execute: addCollaborator, isLoading } = useAddCollaborator()

  const [askToSign, setAskToSign] = useState(false)

  return (
    <Dialog
      open
      onClose={() => {
        setSignerEmail("")
        onClose()
      }}
    >
      <DialogTitle>Add Signer</DialogTitle>
      <DialogContent>
        <p>
          Enter an email address for the signatory you would like to give access
          to this application.
        </p>

        <TextField
          autoFocus
          margin="dense"
          id="signer-email"
          label="Signer Email"
          type="email"
          value={signerEmail}
          onChange={(event) => {
            setSignerEmail(event.target.value)
          }}
          fullWidth
        />

        <FormControlLabel
          style={{ marginTop: "1rem" }}
          control={
            <Checkbox
              checked={askToSign}
              sx={{ color: "primary.main" }}
              onChange={(event) => {
                setAskToSign(event.target.checked)
              }}
            />
          }
          label="Ask to sign the credit terms. This will change the status of the application to 'Awaiting Signature' and send an email with a link to the application to this new contact. Leave this box unchecked if you are only adding a new collaborator."
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSignerEmail("")
            onClose()
          }}
        >
          Back
        </Button>
        <LoadingButton
          id="continue-button"
          loading={isLoading}
          disabled={!isEmail(signerEmail)}
          variant="contained"
          onClick={() => {
            if (applicationId) {
              addCollaborator(applicationId, signerEmail, askToSign, onClose)
            }
          }}
        >
          Continue
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
