import { Box, Chip, Divider, Grid, Typography } from "@mui/material"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import {
  CalendarTodayOutlined,
  MoneyOutlined,
  WarningOutlined,
} from "@mui/icons-material"
import { DelinquencyDetailsSection } from "./DelinquencyDetailsSection"
import { PaymentRatioSection } from "./PaymentRatioSection"
import { CVCreditSummary } from "src/types"

interface AccountSummarySectionProps {
  creditSummary?: CVCreditSummary
  formatCurrency: (amount?: string) => string
  formatDate: (date?: string) => string
}

export const AccountSummarySection = ({
  creditSummary,
  formatCurrency,
  formatDate,
}: AccountSummarySectionProps) => {
  return (
    <Grid item xs={12}>
      <Box>
        <Typography color="text.secondary" gutterBottom>
          TruVision Trended Credit Summary
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Typography color="text.secondary" gutterBottom>
            Total Amounts
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Chip
                icon={<AccountBalanceIcon />}
                label={`Credit Limit: ${formatCurrency(
                  creditSummary?.totalAmount?.creditLimit,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Balance: ${formatCurrency(
                  creditSummary?.totalAmount?.currentBalance,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Payment Due: ${formatCurrency(
                  creditSummary?.totalAmount?.currentPaymentDue,
                )}`}
                variant="outlined"
                size="small"
              />
            </Box>

            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Chip
                label={`Estimated Spend: ${formatCurrency(
                  creditSummary?.totalAmount?.estimatedSpend,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Past Due: ${formatCurrency(
                  creditSummary?.totalAmount?.pastDue,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Most Recent Payment: ${formatCurrency(
                  creditSummary?.totalAmount?.mostRecentPayment?.amount,
                )}`}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>

          <Typography color="text.secondary" gutterBottom>
            Record Counts (Reporting Period:{" "}
            {creditSummary?.recordCounts?.["@reportingPeriod"]})
          </Typography>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Typography color="text.secondary" gutterBottom>
                Open Trade Count: {creditSummary?.recordCounts?.openTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Collection Count: {creditSummary?.recordCounts?.collectionCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Negative Trade Count:{" "}
                {creditSummary?.recordCounts?.negativeTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Total Trade Count:{" "}
                {creditSummary?.recordCounts?.totalTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Revolving Trade Count:{" "}
                {creditSummary?.recordCounts?.revolvingTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Installment Trade Count:{" "}
                {creditSummary?.recordCounts?.installmentTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Other Trade Count:{" "}
                {creditSummary?.recordCounts?.otherTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Public Record Count:{" "}
                {creditSummary?.recordCounts?.publicRecordCount}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Typography color="text.secondary" gutterBottom>
                Total Inquiry Count:{" "}
                {creditSummary?.recordCounts?.totalInquiryCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Mortgage Trade Count:{" "}
                {creditSummary?.recordCounts?.mortgageTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Open Other Trade Count:{" "}
                {creditSummary?.recordCounts?.openOtherTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Open Mortgage Trade Count:{" "}
                {creditSummary?.recordCounts?.openMortgageTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Open Revolving Trade Count:{" "}
                {creditSummary?.recordCounts?.openRevolvingTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Open Installment Trade Count:{" "}
                {creditSummary?.recordCounts?.openInstallmentTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Historical Negative Trade Count:{" "}
                {creditSummary?.recordCounts?.historicalNegativeTradeCount}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Historical Negative Occurrences Count:{" "}
                {
                  creditSummary?.recordCounts
                    ?.historicalNegativeOccurrencesCount
                }
              </Typography>
            </Box>
          </Box>
        </Box>

        {creditSummary?.revolvingAmount && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography color="text.secondary" gutterBottom>
              Revolving Accounts
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 2 }}
            >
              <Chip
                label={`Credit Limit: ${formatCurrency(
                  creditSummary.revolvingAmount?.creditLimit,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Balance: ${formatCurrency(
                  creditSummary.revolvingAmount?.currentBalance,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Estimated Spend: ${formatCurrency(
                  creditSummary.revolvingAmount?.estimatedSpend,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Past Due: ${formatCurrency(
                  creditSummary.revolvingAmount?.pastDue,
                )}`}
                variant="outlined"
                size="small"
                color={
                  creditSummary.revolvingAmount?.pastDue &&
                  parseInt(creditSummary.revolvingAmount?.pastDue) > 0
                    ? "error"
                    : "default"
                }
              />
              <Chip
                label={`Prior Payment Due: ${formatCurrency(
                  creditSummary.revolvingAmount?.priorPaymentDue,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Due: ${formatCurrency(
                  creditSummary.revolvingAmount?.currentPaymentDue,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<MoneyOutlined />}
                label={`Last Payment: ${formatCurrency(
                  creditSummary.revolvingAmount?.mostRecentPayment?.amount,
                )}`}
                variant="outlined"
                size="small"
              />
            </Box>
          </>
        )}
        {creditSummary?.installmentAmount && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography color="text.secondary" gutterBottom>
              Installment Accounts
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 2 }}
            >
              <Chip
                label={`Credit Limit: ${formatCurrency(
                  creditSummary.installmentAmount?.creditLimit,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Balance: ${formatCurrency(
                  creditSummary.installmentAmount.currentBalance,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Estimated Spend: ${formatCurrency(
                  creditSummary.installmentAmount?.estimatedSpend,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Past Due: ${formatCurrency(
                  creditSummary.installmentAmount.pastDue,
                )}`}
                variant="outlined"
                size="small"
                color={
                  creditSummary.installmentAmount?.pastDue &&
                  parseInt(creditSummary.installmentAmount?.pastDue) > 0
                    ? "error"
                    : "default"
                }
              />
              <Chip
                label={`Prior Payment Due: ${formatCurrency(
                  creditSummary.installmentAmount?.priorPaymentDue,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                label={`Current Due: ${formatCurrency(
                  creditSummary.installmentAmount?.currentPaymentDue,
                )}`}
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<MoneyOutlined />}
                label={`Last Payment: ${formatCurrency(
                  creditSummary.installmentAmount?.mostRecentPayment?.amount,
                )}`}
                variant="outlined"
                size="small"
              />
            </Box>
          </>
        )}
        {creditSummary?.mostRecentDelinquency && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography color="text.secondary" gutterBottom>
              Recent Delinquency
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
              <Chip
                icon={<CalendarTodayOutlined />}
                label={`Date: ${formatDate(
                  creditSummary.mostRecentDelinquency?.date?.["#text"],
                )}`}
                color="warning"
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<WarningOutlined />}
                label={`Rating: ${creditSummary.mostRecentDelinquency?.accountRatingCode}`}
                color="error"
                variant="outlined"
                size="small"
              />
            </Box>
          </>
        )}
        {creditSummary?.accountDelinquencySummary && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography color="text.secondary" gutterBottom>
              Delinquency Summary
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, flexDirection: "column", mb: 2 }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                Revolving Accounts
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                <Chip
                  icon={<WarningOutlined />}
                  label={`30 Days: ${creditSummary.accountDelinquencySummary?.revolvingCount?.late30DaysTotal}`}
                  color="warning"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<WarningOutlined />}
                  label={`60 Days: ${creditSummary.accountDelinquencySummary?.revolvingCount?.late60DaysTotal}`}
                  color="warning"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<WarningOutlined />}
                  label={`90+ Days: ${creditSummary.accountDelinquencySummary?.revolvingCount?.late90DaysTotal}`}
                  color="error"
                  variant="outlined"
                  size="small"
                />
              </Box>
              <Typography variant="subtitle2" color="text.secondary">
                Installment Accounts
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                <Chip
                  icon={<WarningOutlined />}
                  label={`30 Days: ${creditSummary.accountDelinquencySummary?.installmentCount?.late30DaysTotal}`}
                  color="warning"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<WarningOutlined />}
                  label={`60 Days: ${creditSummary.accountDelinquencySummary?.installmentCount?.late60DaysTotal}`}
                  color="warning"
                  variant="outlined"
                  size="small"
                />
                <Chip
                  icon={<WarningOutlined />}
                  label={`90+ Days: ${creditSummary.accountDelinquencySummary?.installmentCount?.late90DaysTotal}`}
                  color="error"
                  variant="outlined"
                  size="small"
                />
              </Box>
            </Box>
          </>
        )}

        {creditSummary?.accountDelinquencyDetail && (
          <DelinquencyDetailsSection
            delinquencyDetails={creditSummary.accountDelinquencyDetail}
            formatDate={formatDate}
          />
        )}

        {creditSummary?.totalPaymentRatioInstallment && (
          <PaymentRatioSection
            paymentRatio={creditSummary.totalPaymentRatioInstallment}
            formatCurrency={formatCurrency}
          />
        )}
      </Box>
    </Grid>
  )
}
