import { LoadingButton } from "@mui/lab"
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  TextField,
  DialogActions,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Divider,
  useMediaQuery,
} from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { isEmail } from "src/utils/utils"

import { useAddCollaborator } from "src/queries/credit/useAddCollaborator"
import { usePostAnalytics } from "src/queries/analytics/usePostAnalytics"

export default ({
  open,
  overrideHeight,
  onClose,
  onSubmit,
}: {
  open: boolean
  overrideHeight: boolean
  onClose: () => void
  onSubmit: (first: string, last: string) => void
}) => {
  const [signerEmail, setSignerEmail] = useState("")
  const [isSigner, setIsSigner] = useState<number | undefined>(undefined)
  const [signerFirstName, setSignerFirstName] = useState("")
  const [signerLastName, setSignerLastName] = useState("")

  const { execute: addCollaborator, isLoading } = useAddCollaborator()
  const { execute: saveAnalytics } = usePostAnalytics()

  const [complete, setComplete] = useState(false)
  const matches = useMediaQuery("(max-width: 899px)")
  const params = useParams()
  const { id } = params

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSignerEmail("")
        setSignerFirstName("")
        setSignerLastName("")
        setIsSigner(undefined)
        onClose()
      }}
    >
      <DialogTitle>{complete ? "Success" : "One last thing"}</DialogTitle>
      <DialogContent>
        {!complete && (
          <>
            <p>
              We require a digital signature to finalize your application. By
              clicking continue you confirm that you are the authorized signer
              (usually Owner, Prinicipal, C-Suite or similar).
            </p>
            <p>
              If you are not authorized to sign the credit application, please
              invite the authorized person to sign. They will receive a link via
              email to continue the process.
            </p>
            <FormControl>
              <FormLabel
                id="signature-raio-label"
                style={{ margin: "8px 0 8px 0" }}
              >
                Choose one of the options below:
              </FormLabel>
              <RadioGroup
                aria-labelledby="signature-raio-label"
                defaultValue={undefined}
                name="radio-buttons-group"
                value={isSigner}
                onChange={(e) => setIsSigner(Number(e.target.value))}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="I am the authorized signer (Owner, Prinicipal, C-Suite or similar)."
                />
                {matches && <Divider style={{ margin: "8px 0 8px 0" }} />}
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="I am not the authorized signer. I will invite the authorized signer to sign."
                />
              </RadioGroup>
            </FormControl>

            {isSigner === 0 && (
              <TextField
                autoFocus
                margin="dense"
                id="signer-email"
                label="Signer Email"
                type="email"
                value={signerEmail}
                onChange={(event) => {
                  setSignerEmail(event.target.value)
                }}
                fullWidth
                inputProps={
                  overrideHeight
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
              />
            )}
            {isSigner === 1 && (
              <>
                <p>Please enter your legal first and last name.</p>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: "32px",
                    alignItems: "center",
                    flexDirection: matches ? "column" : "row",
                  }}
                >
                  <TextField
                    id="signer-first-name"
                    fullWidth
                    label="First Name"
                    type="text"
                    required
                    value={signerFirstName}
                    onChange={(event) => {
                      setSignerFirstName(event.target.value)
                    }}
                    inputProps={
                      overrideHeight
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                  />
                  <TextField
                    id="signer-last-name"
                    margin="normal"
                    required
                    fullWidth
                    label="Last Name"
                    type="text"
                    value={signerLastName}
                    onChange={(event) => {
                      setSignerLastName(event.target.value)
                    }}
                    inputProps={
                      overrideHeight
                        ? {
                            style: { height: "56px" },
                          }
                        : {}
                    }
                  />
                </Box>
              </>
            )}
          </>
        )}
        {complete && (
          <>
            <p>
              An email has been sent to the authorized signer with a link to
              complete the application. You can close this window now.
            </p>
            <p>
              Please note that the application is not considered complete until
              it is signed by the authorized signer.
            </p>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSignerEmail("")
            setSignerFirstName("")
            setSignerLastName("")
            setIsSigner(undefined)
            onClose()
          }}
        >
          Back
        </Button>
        {!complete && isSigner !== undefined && (
          <LoadingButton
            id="continue-button"
            loading={isLoading}
            disabled={
              (isSigner === 0 && !isEmail(signerEmail)) ||
              (isSigner === 1 && (!signerFirstName || !signerLastName))
            }
            variant="contained"
            onClick={() => {
              if (isSigner === 0 && signerEmail && id) {
                addCollaborator(id, signerEmail, true, () => {
                  saveAnalytics(
                    "collaborator_invite",
                    "credit",
                    id,
                    "Collaborator Invited",
                    { signerEmail: signerEmail },
                  )
                  setComplete(true)
                })
              }
              if (isSigner === 1) {
                onClose()
                onSubmit(signerFirstName, signerLastName)
              }
            }}
          >
            Continue
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
