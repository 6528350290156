import { Box, Chip, Grid, Typography } from "@mui/material"

interface CreditScoreSectionProps {
  code?: string
  scoreModel?: {
    score?: {
      results?: string
      derogatoryAlert?: string
      fileInquiriesImpactedScore?: string
      factors?: {
        factor?: Array<{
          code?: string
          rank?: string
        }>
      }
    }
  }
}

export const CreditScoreSection = ({
  scoreModel,
  code,
}: CreditScoreSectionProps) => {
  return (
    <Grid item xs={12} md={6}>
      <Box>
        <Typography color="text.secondary" gutterBottom>
          Credit Score {code === "00950" ? "(FICO® Score 98)" : ""}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          <Chip
            label={`Score: ${scoreModel?.score?.results}`}
            color="primary"
            size="small"
          />
          {scoreModel?.score?.derogatoryAlert === "true" && (
            <Chip label="Derogatory Alert" color="error" size="small" />
          )}
          {scoreModel?.score?.factors?.factor &&
            scoreModel?.score?.factors?.factor.length > 0 &&
            "Factors: (Rank 1 being highest-scoring adverse action factor in the calculation)"}
          {scoreModel?.score?.factors?.factor
            ?.sort((factor1, factor2) => {
              if (!factor1.rank) return 1
              if (!factor2.rank) return -1
              if (factor1.rank < factor2.rank) {
                return -1
              }
              return 1
            })
            ?.map((factor, index) => (
              <Chip
                key={index}
                label={`Factor Rank ${factor.rank}: ${factor.code}`}
                variant="outlined"
                size="small"
              />
            ))}
        </Box>
      </Box>
    </Grid>
  )
}
