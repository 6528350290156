import { Card, CardContent, Typography, Box, Chip } from "@mui/material"
import DescriptionIcon from "@mui/icons-material/Description"
import SecurityIcon from "@mui/icons-material/Security"
import WarningIcon from "@mui/icons-material/Warning"
import InfoIcon from "@mui/icons-material/Info"
import { CalendarToday, PersonSearch } from "@mui/icons-material"

interface FileSummaryCardProps {
  data: {
    inFileSinceDate?: {
      "#text": string
    }
    creditDataStatus?: {
      minor?: string
      freeze?: {
        indicator?: string
      }
      disputed?: string
      suppressed?: string
      doNotPromote?: {
        indicator?: string
      }
    }
    fileHitIndicator?: string
    ssnMatchIndicator?: string
    consumerStatementIndicator?: string
  }
}

const FileSummaryCard = ({ data }: FileSummaryCardProps) => {
  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <DescriptionIcon sx={{ mr: 1 }} />
          <Typography variant="h6">File Summary</Typography>
        </Box>

        <Box sx={{ display: "grid", gap: 2 }}>
          <Box>
            <Typography color="text.secondary" gutterBottom>
              File Information
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <Chip
                icon={<CalendarToday />}
                label={
                  data.inFileSinceDate?.["#text"]
                    ? `In File Since: ${new Date(
                        data.inFileSinceDate?.["#text"],
                      ).toLocaleDateString()}`
                    : ""
                }
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<PersonSearch />}
                label={`File Hit: ${data.fileHitIndicator}`}
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>

          <Box>
            <Typography color="text.secondary" gutterBottom>
              Security Status
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <Chip
                icon={<SecurityIcon />}
                label={`SSN Match: ${data.ssnMatchIndicator}`}
                color={
                  data.ssnMatchIndicator === "exact" ? "success" : "warning"
                }
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Freeze Indicator: ${data.creditDataStatus?.freeze?.indicator}`}
                color="warning"
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Disputed: ${data.creditDataStatus?.disputed}`}
                color="warning"
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Suppressed: ${data.creditDataStatus?.suppressed}`}
                color="warning"
                size="small"
              />
              <Chip
                icon={<InfoIcon />}
                label={`Minor: ${data.creditDataStatus?.minor}`}
                color="info"
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Do Not Promote: ${data.creditDataStatus?.doNotPromote?.indicator}`}
                color="warning"
                size="small"
              />
              <Chip
                icon={<InfoIcon />}
                label={`Consumer Statement Indicator: ${data.consumerStatementIndicator}`}
                color="info"
                size="small"
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default FileSummaryCard
