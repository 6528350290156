import { format } from "date-fns"
import {
  Container,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"

import { startCase } from "lodash"
import { CreditData } from "src/types"
import AddOnProductCard from "./components/transunion/AddOnProductCard"
import AddressHistoryCard from "./components/transunion/AddressHistoryCard"
import CustomCard from "./components/transunion/CustomCard"
import FileSummaryCard from "./components/transunion/FileSummaryCard"
import PersonalInfoCard from "./components/transunion/PersonalInfoCard"

const TransUnionReportDialog = ({
  report,
  onClose,
}: {
  report: CreditData
  onClose: () => void
}) => {
  const subjectRecord = report?.creditBureau?.product?.subject?.subjectRecord

  return (
    <Dialog open maxWidth={"lg"}>
      <DialogTitle>TransUnion Credit Report</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {subjectRecord.indicative && (
            <PersonalInfoCard data={subjectRecord.indicative} />
          )}
          {subjectRecord.fileSummary && (
            <FileSummaryCard data={subjectRecord.fileSummary} />
          )}
          <AddOnProductCard data={subjectRecord.addOnProduct} />
          <CustomCard data={subjectRecord} />
          {subjectRecord.indicative?.address && (
            <AddressHistoryCard addresses={subjectRecord.indicative.address} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TransUnionReportDialog
