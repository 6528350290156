import { Box, Chip, Grid, Typography } from "@mui/material"
import SecurityIcon from "@mui/icons-material/Security"

interface FraudAlertSectionProps {
  fraudAlert: {
    highRiskFraudAlert?: {
      // Made optional
      "@searchStatus": string
    }
  }
}

export const FraudAlertSection = ({ fraudAlert }: FraudAlertSectionProps) => {
  return (
    <Grid item xs={12}>
      <Box>
        <Typography color="text.secondary" gutterBottom>
          TruValidate Fraud Alerts
        </Typography>
        <Chip
          icon={<SecurityIcon />}
          label={`Status: ${fraudAlert?.highRiskFraudAlert?.["@searchStatus"]}`}
          color="success"
          size="small"
        />
      </Box>
    </Grid>
  )
}
