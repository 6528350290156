import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Tooltip,
} from "@mui/material"
import AssessmentIcon from "@mui/icons-material/Assessment"
import WarningIcon from "@mui/icons-material/Warning"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import UpdateIcon from "@mui/icons-material/Update"
import PaymentIcon from "@mui/icons-material/Payment"
import HistoryIcon from "@mui/icons-material/History"
import { InfoOutlined } from "@mui/icons-material"

interface MaxDelinquency {
  date?: {
    "#text"?: string
  }
  amount?: string
  earliest?: string
  accountRating?: string
}

interface PaymentPattern {
  text?: string
  startDate?: {
    "#text"?: string
  }
}

interface Trade {
  account?: {
    type?: string
  }
  pastDue?: string
  dateClosed?: {
    "#text"?: string
  }
  dateOpened?: {
    "#text"?: string
  }
  highCredit?: string
  subscriber?: {
    name?: {
      unparsed?: string
    }
  }
  creditLimit?: string
  accountNumber?: string
  accountRating?: string
  dateEffective?: {
    "#text"?: string
  }
  portfolioType?: string
  currentBalance?: string
  paymentHistory?: {
    maxDelinquency?: MaxDelinquency[]
    paymentPattern?: PaymentPattern
    historicalCounters?: {
      late30DaysTotal?: string
      late60DaysTotal?: string
      late90DaysTotal?: string
      monthsReviewedCount?: string
    }
  }
  closedIndicator?: string
  mostRecentPayment?: {
    date?: {
      "#text"?: string
    }
  }
  additionalTradeAccount?: {
    payment?: string
    original?: {
      chargeOff?: string
      creditGrantor?: {
        person?: string
      }
    }
    portfolio?: {
      name?: string
    }
  }
}

interface CustomCardProps {
  data: {
    custom: {
      credit: {
        trade: Trade[]
        // creditSummary?: {
        //   accountRating?: Array<{
        //     count?: string
        //     rating?: string
        //   }>
        // }
      }
    }
  }
}

const CustomCard = ({ data }: CustomCardProps) => {
  const formatCurrency = (amount?: string) => {
    if (!amount) return ""
    const number = parseInt(amount, 10)
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number)
  }

  const formatDate = (date?: string) => {
    if (!date) return ""
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  const colorPattern = (pattern?: string) => {
    if (!pattern) return ""
    return (
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          fontFamily: "monospace",
        }}
      >
        {pattern.split("").map((char) => {
          if (char === "1")
            return (
              <p
                style={{
                  color: "green",
                }}
              >
                🗸
              </p>
            )
          if (char === "E")
            return (
              <p
                style={{
                  color: "green",
                }}
              >
                🗸
              </p>
            )

          return (
            <p
              style={{
                color: "red",
              }}
            >
              {char}
            </p>
          )
        })}
      </Box>
    )
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <AssessmentIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Credit Trade Summary</Typography>
        </Box>

        <Grid container spacing={3}>
          {data.custom.credit.trade.map((trade, index) => (
            <Grid item xs={12} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="subtitle1">
                      {trade.subscriber?.name?.unparsed}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Chip
                        icon={<AccountBalanceIcon />}
                        label={`Account Type: ${trade.account?.type}`}
                        variant="outlined"
                        size="small"
                      />
                      <Chip
                        icon={<UpdateIcon />}
                        label={`Portfolio: ${trade.portfolioType}`}
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                  </Box>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography color="text.secondary" gutterBottom>
                        Account Details
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                        <Chip
                          label={`Account #: ${trade.accountNumber}`}
                          size="small"
                        />
                        <Chip
                          label={`Credit Limit: ${formatCurrency(
                            trade.creditLimit,
                          )}`}
                          size="small"
                        />
                        <Chip
                          label={`Current Balance: ${formatCurrency(
                            trade.currentBalance,
                          )}`}
                          size="small"
                        />
                        <Chip
                          label={`Closed Indicator: ${trade.closedIndicator}`}
                          size="small"
                        />
                        <Chip
                          label={`Account Rating: ${trade.accountRating}`}
                          size="small"
                        />
                        {trade?.pastDue !== undefined && (
                          <Chip
                            label={`Past Due: ${formatCurrency(trade.pastDue)}`}
                            size="small"
                            color={
                              parseInt(trade.pastDue) > 0 ? "error" : "success"
                            }
                          />
                        )}

                        <Chip
                          label={`High Credit: ${formatCurrency(
                            trade.highCredit,
                          )}`}
                          size="small"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography color="text.secondary" gutterBottom>
                        Important Dates
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                        <Chip
                          icon={<HistoryIcon />}
                          label={`Date Effective: ${formatDate(
                            trade.dateEffective?.["#text"],
                          )}`}
                          size="small"
                        />
                        <Chip
                          icon={<HistoryIcon />}
                          label={`Opened: ${formatDate(
                            trade.dateOpened?.["#text"],
                          )}`}
                          size="small"
                        />
                        <Chip
                          icon={<HistoryIcon />}
                          label={`Closed: ${formatDate(
                            trade.dateClosed?.["#text"],
                          )}`}
                          size="small"
                          color="warning"
                        />
                        <Chip
                          icon={<PaymentIcon />}
                          label={`Most Recent Payment: ${formatDate(
                            trade.mostRecentPayment?.date?.["#text"],
                          )}`}
                          size="small"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography color="text.secondary" gutterBottom>
                        Payment History (Last{" "}
                        {
                          trade.paymentHistory?.historicalCounters
                            ?.monthsReviewedCount
                        }{" "}
                        Months)
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                          flexWrap: "wrap",
                          mb: 1,
                        }}
                      >
                        <Chip
                          icon={<WarningIcon />}
                          label={`30 Days Late: ${trade.paymentHistory?.historicalCounters?.late30DaysTotal}`}
                          color="warning"
                          size="small"
                        />

                        <Chip
                          icon={<WarningIcon />}
                          label={`60 Days Late: ${trade.paymentHistory?.historicalCounters?.late60DaysTotal}`}
                          color="warning"
                          size="small"
                        />

                        <Chip
                          icon={<WarningIcon />}
                          label={`90+ Days Late: ${trade.paymentHistory?.historicalCounters?.late90DaysTotal}`}
                          color="error"
                          size="small"
                        />
                      </Box>

                      {trade.paymentHistory?.paymentPattern && (
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            Payment Pattern (Starting{" "}
                            {formatDate(
                              trade.paymentHistory?.paymentPattern?.startDate?.[
                                "#text"
                              ],
                            )}
                            ){" "}
                            <Tooltip
                              title={
                                <Box
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography>Legend:</Typography>
                                  <Typography>
                                    🗸: Paid as agreed, zero balance OR Current
                                    account
                                  </Typography>
                                  <Typography>
                                    2: Account 30 days past due date
                                  </Typography>
                                  <Typography>
                                    3: Account 60 days past due date
                                  </Typography>
                                  <Typography>
                                    4: Account 90 days past due date
                                  </Typography>
                                  <Typography>
                                    5: Account 120 days past due date
                                  </Typography>
                                  <Typography>
                                    7: Wager Earner Plan or Similar plan -
                                    Chapter 13
                                  </Typography>
                                  <Typography>
                                    X: Bankruptcy, Hold rating, Unrated
                                  </Typography>
                                  <Typography>
                                    J: Voluntary surrender
                                  </Typography>
                                  <Typography>
                                    K: Repossession or account paid in full, was
                                    a repossession
                                  </Typography>
                                  <Typography>H: Foreclosure</Typography>
                                  <Typography>
                                    G: Collection or account paid in full, was a
                                    collection
                                  </Typography>
                                  <Typography>
                                    L: Charge-off or account paid in full, was a
                                    charge-off
                                  </Typography>
                                  <Typography>
                                    Y: Represents a gap in monthly payment
                                    reporting
                                  </Typography>
                                </Box>
                              }
                            >
                              <Chip
                                icon={<InfoOutlined />}
                                label={`Legend`}
                                color="info"
                                size="small"
                              />
                            </Tooltip>
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "monospace",
                              mt: 0.5,
                              textTransform: "none",
                              width: "75%",
                            }}
                          >
                            {colorPattern(
                              trade.paymentHistory?.paymentPattern?.text,
                            )}
                          </Typography>
                        </Box>
                      )}

                      {trade.paymentHistory?.maxDelinquency &&
                        trade.paymentHistory?.maxDelinquency.length > 0 && (
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                              Maximum Delinquencies:
                            </Typography>
                            {trade.paymentHistory?.maxDelinquency?.map(
                              (delinquency, idx) => (
                                <Typography key={idx} variant="body2">
                                  {delinquency?.date &&
                                    `Date: ${formatDate(
                                      delinquency?.date?.["#text"],
                                    )}`}
                                  {delinquency?.amount &&
                                    `, Amount: ${formatCurrency(
                                      delinquency?.amount,
                                    )}`}
                                  {delinquency?.accountRating &&
                                    `, Rating: ${delinquency?.accountRating}`}
                                </Typography>
                              ),
                            )}
                          </Box>
                        )}
                    </Grid>

                    {/* {trade.additionalTradeAccount && (
                      <Grid item xs={12}>
                        <Typography color="text.secondary" gutterBottom>
                          Additional Trade Account
                        </Typography>
                        <Chip
                          label={`Charge Off: ${formatCurrency(
                            trade.additionalTradeAccount?.original?.chargeOff,
                          )}`}
                          color="error"
                          size="small"
                        />
                      </Grid>
                    )} */}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CustomCard
