import { Card, CardContent, Typography, Box, Chip } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import SecurityIcon from "@mui/icons-material/Security"

interface PersonalInfoCardProps {
  data: {
    name: {
      person: {
        first: string
        middle: string
        last: string
      }
    }
    dateOfBirth: {
      "#text": string
    }
    socialSecurity: {
      number: string
    }
  }
}

const PersonalInfoCard = ({ data }: PersonalInfoCardProps) => {
  const maskSSN = (ssn: string) => {
    return `***-**-${ssn.slice(-4)}`
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PersonIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Personal Information</Typography>
        </Box>

        <Box sx={{ display: "grid", gap: 2 }}>
          <Box>
            <Typography color="text.secondary" gutterBottom>
              Full Name
            </Typography>
            <Typography variant="body1">
              {`${data.name.person.first} ${data.name.person.middle} ${data.name.person.last}`}
            </Typography>
          </Box>

          <Box>
            <Typography color="text.secondary" gutterBottom>
              Date of Birth
            </Typography>
            <Typography variant="body1">
              {new Date(data.dateOfBirth["#text"]).toLocaleDateString()}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="text.secondary" gutterBottom sx={{ mr: 1 }}>
              Social Security Number
            </Typography>
            <Chip
              icon={<SecurityIcon />}
              label={maskSSN(data.socialSecurity.number)}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default PersonalInfoCard
